body {
  margin: 0;
  padding: 0;
  font-family: sans-serif
}

html, body, #root {
  width: 100%;
  height: 100%
}
